import LexDAOLogo from './assets/lex-dao.png';
import { utils, BigNumber } from 'ethers';

// const { utils } = require('ethers');
const daiRate = BigNumber.from('5');
const ethConvertRate = utils.parseEther('0.000000000000003');
// console.log(ethConvertRate);
export const CONFIG = {
  INFURA_ID: process.env.REACT_APP_INFURA_ID,
  IPFS_ENDPOINT: 'https://ipfs.infura.io',
  BOX_ENDPOINT: 'https://ipfs.3box.io',
  NETWORK_CONFIG: {
    1: {
      SUBGRAPH: 'mattjr/mainnet-rodao-contract',
      WRAPPED_NATIVE_TOKEN:
        '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(),
      INVOICE_FACTORY:
        '0x64e159A40dB55917e7BC2751D5896De2409b5200'.toLowerCase(),
      TOKENS: {
        ['0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase()]: {
          decimals: 18,
          symbol: 'WETH',
        },
      },
      RESOLVERS: {
        ['0x01b92e2c0d06325089c6fd53c98a214f5c75b2ac'.toLowerCase()]: {
          name: 'LexDAO',
          logoUrl: LexDAOLogo,
          termsUrl:
            'https://github.com/lexDAO/Arbitration/blob/master/rules/ToU.md#lexdao-resolver',
        },
      },
      RATE: 20,
      NATIVE_RATE: ethConvertRate,
      DAOS: {
        ['0x33241bFe90A5d5776029a4Ff74E73c8b61Ad863B'.toLowerCase()]: {
          name: 'RoDAO',
          token: {
            ['0x9C7D2c5c4227147438025C68EA3eB2B6923Ee6a2'.toLowerCase()]: {
              decimals: 18,
              symbol: 'ROD',
            },
          },
        },
      },
    },
    100: {
      SUBGRAPH: 'mattjr/xdai-rodao-contract',
      WRAPPED_NATIVE_TOKEN:
        '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d'.toLowerCase(),
      INVOICE_FACTORY:
        '0x811F17259CD3e4755E8dD93CAe07c6cbb1E2D15a'.toLowerCase(),
      TOKENS: {
        ['0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d'.toLowerCase()]: {
          decimals: 18,
          symbol: 'WXDAI',
        },
        ['0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1'.toLowerCase()]: {
          decimals: 18,
          symbol: 'WETH',
        },
      },
      RESOLVERS: {
        ['0x034CfED494EdCff96f0D7160dC2B55Cae5Ee69E1'.toLowerCase()]: {
          name: 'LexDAO',
          logoUrl: LexDAOLogo,
          termsUrl:
            'https://github.com/lexDAO/Arbitration/blob/master/rules/ToU.md#lexdao-resolver',
        },
      },
      RATE: 20,
      NATIVE_RATE: daiRate,
      DAOS: {
        ['0xcdF6B3ceDefA883c57FC1c1E41FF83B3bEbC32c8'.toLowerCase()]: {
          name: 'RoDAO',
          token: {
            ['0x01b92e2c0d06325089c6fd53c98a214f5c75b2ac'.toLowerCase()]: {
              decimals: 18,
              symbol: 'ROD',
            },
          },
        },
      },
    },
    4: {
      SUBGRAPH: 'mattjr/rinkeby-rodao-contract',
      WRAPPED_NATIVE_TOKEN:
        '0xc778417E063141139Fce010982780140Aa0cD5Ab'.toLowerCase(),
      INVOICE_FACTORY:
        '0xde4534c382c33038F4146265704567977082238F'.toLowerCase(),
      TOKENS: {
        ['0xc778417E063141139Fce010982780140Aa0cD5Ab'.toLowerCase()]: {
          decimals: 18,
          symbol: 'WETH',
        },
        ['0x3af6b2f907f0c55f279e0ed65751984e6cdc4a42'.toLowerCase()]: {
          decimals: 18,
          symbol: 'DAI',
        },
        ['0x982e00B16c313E979C0947b85230907Fce45d50e'.toLowerCase()]: {
          decimals: 18,
          symbol: 'TEST',
        },
      },
      RATE: 20,
      NATIVE_RATE: ethConvertRate,
      DAOS: {
        ['0xb7ce5848B5B05EeC7A076e2B8B2d45cfD544e970'.toLowerCase()]: {
          name: 'RoDAO',
          token: {
            ['0xdB250142e8E6045Ae1d995678024EB882bD75BBF'.toLowerCase()]: {
              decimals: 18,
              symbol: 'ROD',
            },
          },
        },
      },
      RESOLVERS: {
        ['0x1206b51217271FC3ffCa57d0678121983ce0390E'.toLowerCase()]: {
          name: 'LexDAO',
          logoUrl: LexDAOLogo,
          termsUrl:
            'https://github.com/lexDAO/Arbitration/blob/master/rules/ToU.md#lexdao-resolver',
        },
      },
    },
  },
};
